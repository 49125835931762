import React, { FC, useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { Button, MessageField, FormField, notification, Modal, Label, useFileQueue } from "@epcnetwork/core-ui-kit";

import { ProjectInputSelector } from "components";
import { createAccountWithFile, CreateIterableAccountData, CreateIterableAccountWithUpload, creteAccount } from "api";
import { CreateIterableAccountProps } from "./create-iterable-account.types";
import { validationSchema, initialValues, emptyProject } from "./create-iterable-account.constants";
import { ProjectsCreator, FileUpload } from "pages/integrations/form";
import { IntegrationFileItem } from "types";

import styles from "../../create-accounts.module.scss";

export const CreateIterableAccount: FC<CreateIterableAccountProps> = ({ isOpen, close, refresh, onCreateSuccess }) => {
  const [projectsInputMode, setProjectsInputMode] = useState<"form" | "file">("form");

  const fileQueue = useFileQueue<IntegrationFileItem, CreateIterableAccountWithUpload>(
    "Suppression",
    createAccountWithFile,
  );

  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(creteAccount);
  onSubmitSuccess(({ response }) => {
    onCreateSuccess(response);
    close();
    notification.success("Iterable account created!", "Iterable account has been successfully created.");
  });
  onSubmitError(() => {
    notification.error("Create error!", "Error occurred while creating a new Iterable account.");
  });

  const handleClose = () => {
    close();
    fileQueue.removeFiles(fileQueue.files.map((file) => file.id));
    fileQueue.clearEntity();
  };

  useDidUpdate(() => {
    setProjectsInputMode("form");
  }, [isOpen]);

  useDidUpdate(() => {
    const haveError = fileQueue.files.some((file) => Boolean(file.error));

    if (fileQueue.isEntityFinished && !haveError) {
      handleClose();
      refresh();
      notification.success(
        "Iterable account created!",
        "Iterable account with projects have been successfully created.",
      );
    }

    if (fileQueue.isEntityFinished && haveError) {
      notification.error("Create error!", "Error occurred while creating the Iterable account.");
    }
  }, [fileQueue.isEntityFinished]);

  const handleSubmit = async (values: CreateIterableAccountData) => {
    const { globalSwitch, ...formValues } = values;

    if (fileQueue.files.length === 0) return submit({ data: formValues });

    const data = fileQueue.files.map((file) => {
      const formData = new FormData();

      const { hasHeaders, headers } = file.data;
      const { projects, globalSwitch, ...formValues } = values;

      const nameIndex = headers.findIndex((header) => header === "name");
      const apiKeyIndex = headers.findIndex((header) => header === "apiKey");

      formData.append("form", JSON.stringify({ ...formValues, ...globalSwitch, hasHeaders, nameIndex, apiKeyIndex }));
      formData.append("file", file.originalFile);

      return {
        id: file.id,
        data: formData,
      };
    });

    await fileQueue.submitOneByOne(data);
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, isValid }) => {
          const handleFormInputMode = async () => {
            setProjectsInputMode("form");
            fileQueue.removeFiles(fileQueue.files?.[0]?.id);
            await setFieldValue("projects", [...values.projects, emptyProject]);
          };

          const handleUploadMode = async () => {
            if (projectsInputMode === "file") return null;
            setProjectsInputMode("file");
            await setFieldValue("projects", []);
          };

          return (
            <Form>
              <div className={styles.title}>Add Iterable integration</div>
              <MessageField message={error?.message || ""} />
              <div className={styles.row}>
                <FormField type="text" name="name" label="Account name" disableFloatingLabel required />
                <FormField type="text" name="owner" label="Owner" disableFloatingLabel required />
              </div>
              <FormField type="text" name="proxy" label="Proxy" disableFloatingLabel required />

              <div>
                <Label text="Projects" isInputLabel />
                <p className={styles.labelDescription}>You can create projects now or add them later.</p>
              </div>

              <ProjectInputSelector
                isListEmpty={values.projects.length === 0}
                handleFormInput={handleFormInputMode}
                handleUploadInput={handleUploadMode}
              />
              {projectsInputMode === "form" && <ProjectsCreator />}
              {projectsInputMode === "file" && (
                <div>
                  <FileUpload fileQueue={fileQueue} integrationMode="iterable" />

                  <div className={styles.labelsWrapper}>
                    <div className={styles.labelRow}>
                      <FormField type="switch" name={`globalSwitch.enableUnforget`} disableError />
                      <div>
                        <Label text="Unforget" isInputLabel />
                        <p className={styles.labelSpan}>
                          The Unforget switch removes forgotten emails after 90 days, provided no complaints have been
                          received from the email during that time.
                        </p>
                      </div>
                    </div>
                    <div className={styles.labelRow}>
                      <FormField type="switch" name={`globalSwitch.enableComplaintsForwarding`} disableError />
                      <div>
                        <Label text="Complaints forwarding" isInputLabel />
                        <p className={styles.labelSpan}>
                          The complaints forwarding switch automatically forwards emails to Salesforce when a complaint
                          is received.
                        </p>
                      </div>
                    </div>
                    <div className={styles.labelRow}>
                      <FormField type="switch" name={`globalSwitch.mergexImport`} disableError />
                      <div>
                        <Label text="Import Mergex suppression list" isInputLabel />
                        <p className={styles.labelSpan}>
                          Import suppressions list from Mergex and forget them on Iterable projects.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className={styles.buttons}>
                <Button appearance="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!fileQueue.hasAllConfigured || !isValid}>
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
