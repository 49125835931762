import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import {
  Button,
  DropAcceptedFunc,
  FileItem,
  FileUpload,
  notification,
  Nullable,
  parseBaseFile,
  useFileQueue,
} from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useSelector } from "react-redux";

import { DASHBOARD_PAGE, JOBS_LIST_PAGE } from "constants/routes.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { getInitialData, getSupportedFormats } from "./suppress-from-file.utils";
import { SuppressionValuesModal } from "../suppression-values-modal/suppression-values-modal";
import { SuppressionFileItem } from "./suppress-from-file.types";
import { steps } from "./suppress-from-file.tour";
import { createSuppressionsWithFile, SuppressionData, SuppressionFileInfo } from "api";
import { RootState } from "store";
import { useTour } from "hooks";
import { ListsSelect } from "../lists-select/lists-select";

import styles from "./suppress-from-file.module.scss";

export const SuppressFromFile: React.FC = () => {
  useTour({
    name: "iterable-details",
    steps,
  });

  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.auth);

  const [editedFile, setEditedFile] = useState<Nullable<SuppressionFileItem>>(null);
  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
  const [selectedDataExtensions, setSelectedDataExtensions] = useState<number[]>([]);

  const {
    files,
    createInitialFile,
    addFiles,
    updateFiles,
    removeFiles,
    clearEntity,
    getItem,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllConfigured,
    submitOneByOne,
    hasAllSuccessFiles,
  } = useFileQueue<SuppressionFileItem, SuppressionData>("Suppression", createSuppressionsWithFile);

  const handleDropAccept: DropAcceptedFunc = useCallback(
    async (acceptedFiles) => {
      const [parsedFile] = await parseBaseFile(acceptedFiles[0], Infinity);

      if (parsedFile.length <= 10000) {
        addFiles(createInitialFile(acceptedFiles[0], { data: getInitialData(acceptedFiles[0]) }));
      } else {
        notification.error("This CSV contains more than 10,000 emails", "Please upload a smaller file");
      }
    },
    [addFiles, createInitialFile],
  );

  const handleSubmit = async () => {
    const data = files.map((file) => {
      const formData = new FormData();

      const { fileName, emailIndex, hasHeaders } = file.data;
      const suppressionData: SuppressionFileInfo = {
        emailIndex,
        hasHeaders,
        projectIds: selectedProjects,
        dataExtensionIds: selectedDataExtensions,
      };

      formData.append("form", JSON.stringify(suppressionData));
      formData.append("file", file.originalFile, fileName);

      return {
        id: file.id,
        data: formData,
      };
    });

    await submitOneByOne(data);
  };

  useDidUpdate(() => {
    const haveError = Boolean(files[0]?.error);

    if (isEntityFinished && !haveError) {
      notification.success("Success!", "Emails were added to queue.");

      removeFiles(files[0].id);
      clearEntity();

      navigate(user?.isAdmin ? JOBS_LIST_PAGE.path : DASHBOARD_PAGE.path);
    }

    if (isEntityFinished && haveError) {
      const error = files[0]?.error;
      notification.error("Error!", `Could not add emails to queue. ${error}`);
    }
  }, [isEntityFinished]);

  const closeModal = () => setEditedFile(null);

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: SuppressionFileItem) => {
      updateFiles({ id, file: rest });
      setEditedFile(null);
    },
    [updateFiles],
  );

  const handleItemConfiguration = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setEditedFile(item);
    },
    [getItem],
  );

  const getAcceptedFilesText = (formats: string[]): string => `Accepted ${formats.join(", ")} files`;

  const supportedFormats = [csvFormat, txtFormat].flat();

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Upload a CSV file with emails you want to add as suppressions</h2>
      <div className={styles.form}>
        <FileUpload
          className={classNames(styles.dropZone, { [styles.uploadZone]: files.length })}
          uploadedFilesLength={files.length}
          subtitle={`${getAcceptedFilesText(getSupportedFormats(supportedFormats))} - files with max 10 000 lines are accepted.`}
          accept={supportedFormats}
          onDropAccepted={handleDropAccept}
          exceedFilesOption="splice-with-error"
          disabled={!isEntityInConfiguration}
          preventDropOnDocument
          multiple={false}
          // todo: add max size?
          // maxSize={300000}
        />
        <div className={styles.fileList}>
          {files.map(({ id, originalFile, data, ...rest }) => {
            const showFileContent = data.emailIndex >= 0;

            return (
              <FileItem
                {...rest}
                key={id}
                id={id}
                file={originalFile}
                onCrossClick={removeFiles}
                onSetValuesClick={handleItemConfiguration}
                onEditValuesClick={handleItemConfiguration}
              >
                {showFileContent && (
                  <div className={styles.column}>
                    {data.emailIndex >= 0 && (
                      <div className={styles.additionalInfo}>
                        <span>Selected email column:</span> {data.emailIndex + 1}
                      </div>
                    )}
                  </div>
                )}
              </FileItem>
            );
          })}
        </div>
        {editedFile && (
          <SuppressionValuesModal file={editedFile} onCloseClick={closeModal} onSubmitClick={handleModalSubmit} />
        )}

        <ListsSelect
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
          selectedDataExtensions={selectedDataExtensions}
          setSelectedDataExtensions={setSelectedDataExtensions}
        />

        <div className={styles.buttons}>
          {!isEntityFinished && (
            <Button
              onClick={handleSubmit}
              disabled={!hasAllConfigured || !files.length || !selectedProjects.length}
              loading={!isEntityInConfiguration}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
