interface EnvironmentValue {
  apiUrl: string;
  googleClientId: string;
  nodeEnv: "development" | "production" | "test";
  devSignInEmail: string;
}

export const appEnvironment: EnvironmentValue = {
  apiUrl: process.env.REACT_APP_API as string,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
  nodeEnv: process.env.NODE_ENV || "development",
  devSignInEmail: process.env.REACT_APP_DEV_EMAIL as string,
};
