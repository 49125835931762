import { FC, useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form, FormikProps } from "formik";
import {
  Button,
  MessageField,
  FormButtons,
  FormField,
  notification,
  Modal,
  Label,
  Switch,
} from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { updateProject, UpdateProjectData } from "api";
import { IterableProjectModel } from "models";
import { UpdateProjectProps } from "./update-iterable-project.types";
import { validationSchema, initialValues } from "./update-iterable-project.constants";

import styles from "./update-iterable-project.module.scss";

export const UpdateIterableProject: FC<UpdateProjectProps> = ({ onUpdateSuccess, project, isOpen, close }) => {
  const [apiKeyEnabled, setApiKeyEnabled] = useState<boolean>(false);

  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(updateProject);
  onSubmitSuccess(({ response }) => {
    onUpdateSuccess(response);
    close();
    notification.success("Project updated!", "Project has been successfully updated.");
  });
  onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the project.");
  });

  useDidUpdate(() => {
    setApiKeyEnabled(false);
  }, [isOpen]);

  const handleSubmit = async (values: UpdateProjectData) => {
    if (project) {
      const { apiKey, ...rest } = values;
      const data = apiKeyEnabled ? values : rest;
      await submit({ data, params: { projectId: project.id } });
    }
  };

  const handleSwitchToggle =
    (formikProps: FormikProps<Partial<Pick<IterableProjectModel, "name" | "apiKey" | "isActive">>>) =>
    (value: string, checked: boolean) => {
      setApiKeyEnabled(checked);
      if (!checked) {
        formikProps.setFieldValue("apiKey", "");
        formikProps.setFieldTouched("apiKey", false, false);
      }
    };

  return (
    <Modal isOpen={isOpen} setClose={close}>
      <Formik
        initialValues={initialValues(project)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(apiKeyEnabled)}
        enableReinitialize
      >
        {(props) => {
          return (
            <Form>
              <div className={styles.title}>Update Iterable Project</div>
              <p className={styles.subtitle}>
                If the switch is checked, a new API Key will be required. When the switch is not checked, the key that
                was added earlier will be used.
              </p>
              <MessageField message={error?.message || ""} />
              <div className={styles.row}>
                <div>
                  <div className={styles.alignLabelRow}>
                    <Label text="Project name" isInputLabel />
                  </div>
                  <FormField type="text" name="name" disableFloatingLabel required />
                </div>
                <div>
                  <div className={styles.labelRow}>
                    <Label text="API key" isInputLabel />
                    <Switch value="test" disableError checked={apiKeyEnabled} onChange={handleSwitchToggle(props)} />
                  </div>
                  <FormField
                    type="text"
                    name="apiKey"
                    placeholder={apiKeyEnabled ? "Provide a new API Key" : "Use the same API Key"}
                    disableFloatingLabel
                    disabled={!apiKeyEnabled}
                  />
                </div>
              </div>
              <div className={styles.labelsWrapper}>
                <div className={styles.labelRow}>
                  <FormField type="switch" name="isActive" disableError />
                  <div>
                    <Label text="Project active" isInputLabel />
                    <p className={styles.labelSpan}>
                      The switch toggles a project on or off, ensuring it is excluded from suppressions when turned off.
                    </p>
                  </div>
                </div>
                <div className={styles.labelRow}>
                  <FormField type="switch" name="enableUnforget" disableError disabled={!props.values.isActive} />
                  <div>
                    <Label text="Unforget" isInputLabel />{" "}
                    {!props.values.isActive && (
                      <span className={styles.labelSpan}>(Disabled when project is not active.)</span>
                    )}
                    <p className={styles.labelSpan}>
                      The &apos;Unforget&apos; removes forgotten emails after 90 days, provided no complaints have been
                      received from the email during that time.
                    </p>
                  </div>
                </div>
                <div className={styles.labelRow}>
                  <FormField
                    type="switch"
                    name="enableComplaintsForwarding"
                    disableError
                    disabled={!props.values.isActive}
                  />
                  <div>
                    <Label text="Complaints forwarding" isInputLabel />
                    {!props.values.isActive && (
                      <span className={styles.labelSpan}>(Disabled when project is not active.)</span>
                    )}
                    <p className={styles.labelSpan}>
                      The complaints forwarding automatically forwards emails to Salesforce when a complaint is
                      received.
                    </p>
                  </div>
                </div>
              </div>
              <FormButtons>
                <Button appearance="secondary" onClick={close}>
                  Cancel
                </Button>
                <Button type="submit">Submit</Button>
              </FormButtons>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
