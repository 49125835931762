import React from "react";
import { Button, Label, Select, useAutocomplete } from "@epcnetwork/core-ui-kit";
import { useNavigate } from "react-router-dom";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { getActiveDataExtensions, getActiveProjects } from "api";
import { INTEGRATIONS_LIST_PAGE } from "constants/routes.constants";
import { Plus } from "assets";

import styles from "./lists-select.module.scss";

interface Props {
  selectedProjects: number[];
  setSelectedProjects: React.Dispatch<React.SetStateAction<number[]>>;
  selectedDataExtensions: number[];
  setSelectedDataExtensions: React.Dispatch<React.SetStateAction<number[]>>;
}

export const ListsSelect: React.FC<Props> = ({
  selectedProjects,
  setSelectedProjects,
  selectedDataExtensions,
  setSelectedDataExtensions,
}) => {
  const navigate = useNavigate();
  const projectsAutocomplete = useAutocomplete(getActiveProjects, "id", "name");
  const dataExtensionsAutocomplete = useAutocomplete(getActiveDataExtensions, "id", "name");

  useDidUpdate(() => {
    setSelectedProjects(() => projectsAutocomplete.fetchOptions.map((item) => item.id));
  }, [projectsAutocomplete.fetchOptions]);

  useDidUpdate(() => {
    setSelectedDataExtensions(() => dataExtensionsAutocomplete.fetchOptions.map((item) => item.id));
  }, [dataExtensionsAutocomplete.fetchOptions]);

  const projectsLoading = projectsAutocomplete.details.status === 0 || projectsAutocomplete.loading;
  const dataExtensionsLoading = dataExtensionsAutocomplete.details.status === 0 || dataExtensionsAutocomplete.loading;

  const projectsEmpty = projectsAutocomplete.fetchOptions.length === 0 && !projectsLoading;
  const dataExtensionsEmpty = dataExtensionsAutocomplete.fetchOptions.length === 0 && !dataExtensionsLoading;

  return (
    <div className="suppress-details">
      <div className={styles.projectSelect}>
        <Label text="Projects to suppress" isInputLabel />

        {projectsEmpty && (
          <div>
            <p className={styles.projectDescription}>
              You currently have no active projects. Click the button bellow, select the account and activate or create
              a new project.
            </p>

            <Button appearance="primary" onClick={() => navigate(INTEGRATIONS_LIST_PAGE.path)} btnSize="small">
              <Plus />
              Create first project
            </Button>
          </div>
        )}
        {!projectsEmpty && (
          <>
            <p className={styles.projectDescription}>Select projects these suppressions will be added to</p>
            <Select
              name="projectIds"
              options={[]}
              isSearchable
              selectedOptionsKeys={selectedProjects}
              onChange={(options) => setSelectedProjects(options.map((item) => item.id))}
              searchPlaceholder="Search projects"
              asyncOptions={projectsAutocomplete}
              disabled={projectsAutocomplete.loading}
              error={
                selectedProjects.length === 0 && !projectsLoading ? "At least one Iterable project is required" : ""
              }
              isMulti
            />
          </>
        )}
      </div>

      <div className={styles.projectSelect}>
        <Label text="Data extensions to suppress" isInputLabel />

        {dataExtensionsEmpty && (
          <>
            <div>
              <p className={styles.projectDescription}>
                You currently have no active data extensions. Click the button bellow, select the business unit and
                activate or create a new data extension.
              </p>

              <Button
                appearance="primary"
                onClick={() => navigate(`${INTEGRATIONS_LIST_PAGE.path}?tab=salesforce`)}
                btnSize="small"
              >
                <Plus />
                Create first data extension
              </Button>
            </div>
          </>
        )}

        {!dataExtensionsEmpty && (
          <>
            <p className={styles.projectDescription}>Select data extensions to which suppresses will be added</p>
            <Select
              name="dataExtensionIds"
              options={[]}
              selectedOptionsKeys={selectedDataExtensions}
              onChange={(options) => setSelectedDataExtensions(options.map((item) => item.id))}
              isSearchable
              searchPlaceholder="Search data extensions"
              asyncOptions={dataExtensionsAutocomplete}
              disabled={dataExtensionsAutocomplete.loading}
              isMulti
            />
          </>
        )}
      </div>
    </div>
  );
};
