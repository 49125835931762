import { Route } from "config/routes.config";
import { PermissionTuple } from "models";

export const LOGIN_PAGE: Route = {
  path: "/login",
  name: "Login",
  auth: false,
} as const;

export const DASHBOARD_PAGE: Route = {
  path: "/dashboard",
  name: "Dashboard",
  auth: true,
  showNavigation: true,
  permission: ["read", "dashboard"] as PermissionTuple,
  navbarClassName: "dashboard-navbar",
} as const;

export const INTEGRATIONS_LIST_PAGE: Route = {
  path: "/integrations",
  name: "Integrations",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;

export const JOBS_LIST_PAGE: Route = {
  path: "/jobs",
  name: "Queues",
  description: "List of queues with added suppressions. Click on queue to see all the details.",
  auth: true,
  showNavigation: true,
  permission: ["read", "jobs"] as PermissionTuple,
  navbarClassName: "jobs-list-navbar",
} as const;

export const JOB_DETAILS_PAGE: Route = {
  path: "/jobs/:jobId",
  name: "Queue details",
  auth: true,
  showNavigation: true,
  hideGlobalNavbar: true,
  permission: ["read", "jobs"] as PermissionTuple,
} as const;

export const ACCOUNT_DETAILS_PAGE: Route = {
  path: "/account/:accountId",
  name: "Iterable integration details",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;

export const BUSINESS_UNIT_DETAILS_PAGE: Route = {
  path: "/business-unit/:businessUnitId",
  name: "Salesforce integration details",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;

export const USERS_LIST_PAGE: Route = {
  path: "/users",
  name: "Users",
  description: "List of all users with their roles.",
  auth: true,
  showNavigation: true,
  permission: ["read", "users"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;

export const AUDIT_LOGS_PAGE: Route = {
  path: "/audit",
  name: "Audit events",
  description: "List of all events across the application.",
  auth: true,
  showNavigation: true,
  permission: ["read", "audit-logs"] as PermissionTuple,
  navbarClassName: "audit-logs-navbar",
} as const;

export const CREATE_SUPPRESSION_PAGE: Route = {
  path: "/create-suppression",
  name: "Create suppression",
  description: "Create a new suppression. Add emails manually or upload a file.",
  auth: true,
  showNavigation: true,
  permission: ["create", "suppressions"] as PermissionTuple,
  navbarClassName: "create-suppression-navbar",
} as const;

export const SETTINGS_PAGE: Route = {
  path: "/settings",
  name: "Settings",
  description: "Update your account settings",
  auth: true,
  showNavigation: true,
  navbarClassName: "settings-navbar",
} as const;

export const DEFAULT_PAGE: Route = {
  ...DASHBOARD_PAGE,
  path: "/",
} as const;

export const LOGOUT_PAGE: Route = {
  path: "/logout",
  name: "Logout",
  auth: false,
  showNavigation: false,
} as const;

export const NO_ACCESS_PAGE: Route = {
  path: "/no-access",
  name: "No access",
  auth: true,
  showNavigation: true,
  hideGlobalNavbar: true,
} as const;

export const NOT_FOUND_PAGE: Route = {
  path: "*",
  name: "404 - Not found",
  auth: true,
  showNavigation: true,
  hideGlobalNavbar: true,
} as const;
