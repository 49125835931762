import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Business units page</h2>
    <p>
      The Salesforce Business Units page enables admins to list, view the data extensions count, and manage all
      Salesforce business units.
    </p>
    <p>
      This page provides a comprehensive view of all units, helping admins track and control how each business unit
      contributes to the suppression processes.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Create Salesforce business unit button</h2>

    <p>The Create button allows admins to add a new Salesforce business unit.</p>
    <p>
      By clicking this, you can quickly set up new units and link data extensions to them for suppression tasks, making
      it easy to manage Salesforce data within the application.
    </p>
  </div>
);

const thirdStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Accounts list</h2>
    <p>
      This table displays all Salesforce business units, providing essential information such as account name, client
      ID, auth URL, account ID and data extensions counter.
    </p>
    <p>
      Each row includes an active switch, which enables or disables the entire business unit, including all its
      associated data extensions. If an account is marked as inactive, it will be excluded from any suppression tasks,
      ensuring precise control over which accounts are processed.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".integrations-navbar", on: "bottom-start" },
    permission: ["read", "integrations"],
  },

  {
    text: renderToString(secondStep),
    attachTo: { element: ".add-salesforce", on: "top" },
    permission: ["create", "integrations"],
  },
  {
    text: renderToString(thirdStep),
    attachTo: { element: ".table", on: "top" },
    permission: ["read", "integrations"],
  },
];
