import React, { FC, useMemo, useState } from "react";
import { Button, Search, Tabs, useDidUpdate, useFilters } from "@epcnetwork/core-ui-kit";

import { IterableAccountModel, BusinessUnitModel } from "models";
import { useItemManagement, usePermissions, useSidebar } from "hooks";
import { Container } from "components";
import { IterableAccountsTable } from "./iterable/iterable-accounts-table";
import { SalesforceBusinessUnitTable } from "./salesforce/salesforce-business-unit-table";
import { getInitialStorageFilters } from "utils";
import { Plus } from "assets";
import { Navbar } from "components/layout/navbar/navbar";
import { Integration } from "types";
import { tabsArray, getInitialActiveTab, filtersOptions } from "./integrations-list.constants";
import { IntegrationsQuery } from "./integrations-list.types";

import styles from "./integrations-list.module.scss";

export const IntegrationsListPage: FC = () => {
  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();
  const { create: createAccount } = useItemManagement<IterableAccountModel>();
  const { create: createBusinessUnit } = useItemManagement<BusinessUnitModel>();
  const { query, searchValue, setSearch, setQueryParams } = useFilters<IntegrationsQuery>(
    getInitialStorageFilters<IntegrationsQuery>("integrations", filtersOptions),
  );

  const [canCreateAccount] = usePermissions("create", "accounts");
  const [activeTab, setActiveTab] = useState(0);

  useDidUpdate(
    () => {
      if (query.tab) setActiveTab(getInitialActiveTab(query.tab));
    },
    [query.tab],
    true,
  );

  const handleTabClick = (index: number) => {
    const tab: Integration = tabsArray[index] as Integration;
    setQueryParams({ tab, search: "" });
    setActiveTab(index);
  };

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);

  const tabs = [
    {
      tab: { name: "Iterable" },
      tabComponent: (
        <IterableAccountsTable
          searchQuery={query}
          createAccountOpen={createAccount.isOpen}
          closeCreate={createAccount.close}
          switchToSalesforce={() => handleTabClick(1)}
        />
      ),
    },
    {
      tab: { name: `Salesforce ET` },
      tabComponent: (
        <SalesforceBusinessUnitTable
          searchQuery={query}
          createBusinessUnitOpen={createBusinessUnit.isOpen}
          closeCreate={createBusinessUnit.close}
        />
      ),
    },
  ];

  const { iterableTab, salesforceTab } = useMemo(() => {
    const iterableTab = activeTab === 0;
    const salesforceTab = activeTab === 1;
    return { iterableTab, salesforceTab };
  }, [activeTab]);

  const pageName = iterableTab ? "Iterable accounts" : "Salesforce business units";
  const pageDescription = iterableTab
    ? "List of all Iterable accounts. Click on item to see all projects added to this account."
    : "List of all Salesforce business units. Click on item to see all data extensions added to this account.";

  return (
    <>
      <Navbar
        isMenuCollapsed={isMenuCollapsed}
        toggleSidebar={toggleSidebar}
        name={pageName}
        description={pageDescription}
        navbarClassName="integrations-navbar"
      />
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Search className={styles.search} searchValue={searchValue} setSearch={setSearch} />
            {canCreateAccount && iterableTab && (
              <Button onClick={createAccount.open} className="add-iterable">
                <Plus /> Add Iterable integration
              </Button>
            )}
            {canCreateAccount && salesforceTab && (
              <Button onClick={createBusinessUnit.open} className="add-salesforce">
                <Plus /> Add Salesforce integration
              </Button>
            )}
          </div>
          <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
        </div>
      </Container>
    </>
  );
};
