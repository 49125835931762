import React, { useState } from "react";
import { useFetch } from "@hyper-fetch/react";
import { Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { Container, TableError, TableNoContent } from "components";
import {
  JobListListenerEventsKeys,
  JobProgressUpdateData,
  JobUpdateData,
  useJobListSocket,
  useTablePagination,
  useTour,
} from "hooks";
import { getJobs, GetJobsQuery } from "api";
import { JobItem } from "./table-item/job-item";
import { getInitialStorageFilters, updateItem } from "utils";
import { initialFilters, jobListColumns, TABLE_NAME } from "./job-list.constants";
import { JobModel, List } from "models";
import { steps } from "./jobs-list.tour";

import styles from "./jobs-list.module.scss";

export const JobsListPage: React.FC = () => {
  useTour({
    name: "job-list",
    steps,
  });

  const { socket } = useJobListSocket();
  const { query, searchValue, setSearch } = useFilters<GetJobsQuery>(
    getInitialStorageFilters<GetJobsQuery>(TABLE_NAME, initialFilters),
  );

  const [jobs, setJobs] = useState<List<JobModel> | null>(null);

  const { loading, refetch, error, onSuccess } = useFetch(getJobs.setQueryParams(query));
  onSuccess(({ response }) => setJobs(response));

  const pagination = usePagination({ listPayload: jobs });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  useDidUpdate(
    () => {
      const handleJobListUpdate = ({
        suppressionJobId,
        status,
        finishedCount,
      }: JobUpdateData | JobProgressUpdateData) => {
        setJobs((prevState) => {
          if (!prevState) return null;

          const updatedState = prevState.data.map((job) => {
            if (job.id === suppressionJobId) {
              if (status) return { ...job, status };
              if (finishedCount) return { ...job, finishedCount };
            }

            return job;
          });

          return { ...prevState, data: updatedState };
        });
      };

      if (socket) {
        socket.on<JobListListenerEventsKeys>("jobUpdate", handleJobListUpdate);

        return () => {
          socket.off<JobListListenerEventsKeys>("jobUpdate", handleJobListUpdate);
        };
      }
    },
    [socket],
    true,
  );

  const handleTerminateSuccess = (job: JobModel) => {
    if (jobs) updateItem(job, jobs, setJobs);
  };

  return (
    <Container>
      <div className={styles.header}>
        <Search searchValue={searchValue} setSearch={setSearch} />
      </div>

      <Table
        entityName={TABLE_NAME}
        columns={jobListColumns}
        list={jobs?.data}
        error={error?.message}
        refresh={() => refetch()}
        loading={loading}
        resetColumnsOnMount={false}
        isTabTable
        customNoContent={<TableNoContent withBackground />}
        customError={<TableError description={error?.message} withBackground />}
        pagination={{
          ...pagination,
          elementsPerPage: currentElementsPerPage,
          onElementsPerPageChange: handlePerPageChange,
        }}
        row={(job) => <JobItem job={job} onTerminateSuccess={handleTerminateSuccess} />}
      />
    </Container>
  );
};
